<template>
  <div class="rt_monitor_map">
    <div id="map"></div>
    <div class="search">
       <el-select
                v-model="input"
                filterable
                :remote-method="remoteMethod"
              @change='selectChange'
              remote
              reserve-keyword
              value-key='id'
                size='mini'
                placeholder="请输入地点关键字查询">
                <el-option
                  v-for="(item,index) in addressArray"
                  :key="index"
                  :label="item.label"
                  :value="item">
                  <span style="float: left;margin-right:20px">{{ item.name }}</span>
      <span style="float: right; color: #ccc; font-size: 12px">{{ item.district }}</span>
                </el-option>
              </el-select>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
// import qs from 'qs';
// import axios from 'axios';
export default {
  data() {
    return {
      //地图实例
      map: null,
      marker: null,
      lineArr: null,
      value1: [],
      state: {
        lng: 120,
        lat: 111,
        address: "",
      },
      addressArray:[],
      input:{},
    };
  },
  props: ["addressData"],
  methods: {
    //获取数据
    remoteMethod(str){
      let _this=this
      AMap.plugin('AMap.Autocomplete', function(){
          // 实例化Autocomplete
          var autoOptions = {
            //city 限定城市，默认全国
            city: '全国'
          }
          var autoComplete= new AMap.Autocomplete(autoOptions);
          var keyword = str;
          autoComplete.search(keyword, function(status, result) {
            // 搜索成功时，result即是对应的匹配数据
            if (status === "complete" && result.info=='OK') {
              let arr= []

              result.tips.forEach(item=>{
                arr.push({
                  label:item.district+item.address,
                  name:item.name,
                  district:item.district,
                  location:item.location,
                  id:item.id,
                })
              })
           _this.addressArray=arr
            }else{
              _this.$message('未查询到对应地理信息')
            }
          })
        })
    },
    selectChange(d){
      this.state={
        lng:d.location.lng,
        lat:d.location.lat,
        address:d.label,
        areaName:d.district.substring(d.district.indexOf("市")+1)
      }
       if (this.marker) {
        this.map.remove(this.marker);
      }
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(d.location.lng, d.location.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      this.map.add(this.marker);
      this.map.setZoomAndCenter(14, [
        d.location.lng,
        d.location.lat,
      ]);
      this.$emit("childByValue",this.state);
    }
  },
  mounted() {
    //实例地图对象
    var map = new AMap.Map("map", {
      zoom: 11, //级别
      center: [103.993864, 30.447862], //中心点坐标
    });
    var _this = this;
    this.map = map;
    // if (this.addressData.lng) {
    //   _this.marker = new AMap.Marker({
    //     position: new AMap.LngLat(_this.addressData.lng, _this.addressData.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //   });
    //   map.add(_this.marker);
    // }
    map.on("click", function (e) {
      if (_this.marker) {
        map.remove(_this.marker);
      }
      _this.state.lng = e.lnglat.lng;
      _this.state.lat = e.lnglat.lat;

      AMap.service("AMap.Geocoder", function () {
        //实例化Geocoder
        let geocoder = new AMap.Geocoder({
          city: "", //城市，默认：“全国”
        });
        var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()]; //地图上所标点的坐标
        geocoder.getAddress(lnglatXY, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            //获得了有效的地址信息:
            //即，result.regeocode.formattedAddress
            console.log(result);
            _this.state.address = result.regeocode.formattedAddress;
            _this.state.areaName = result.regeocode.addressComponent.district;
            _this.$emit("childByValue", _this.state);
          }
        });
      });

      _this.marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.lng, e.lnglat.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      map.add(_this.marker);
    });
    map.setFitView();
  },
};
</script>

<style lang="less">
.rt_monitor_map {
  width: 100%;
  height: 100%;
  position: relative;
  #map {
    width: 100%;
    height: 100%;
    .amap-box {
      width: 100%;
      height: 100%;
      .amap-info-content {
        padding: 0px !important;
      }
      .number {
        display: inline-block;
        color: #ccc;
        background-color: rgba(0, 0, 0, 0.2);
        font-size: 12px;
        width: 20px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
  .search{
    position: absolute;
    top: 10px;
    left: 20px;
    input{
      width: 300px;
    }
  }
  .head_item {
    position: absolute;
    top: 0px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding-left: 20px;
    button {
      margin-left: 20px;
    }
    i {
      font-size: 18px;
    }
  }
}
</style>