<template>
  <div class="O_manage">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <aside v-show="hide == 1">
        <div class="title">
          <p>
            <img src="../assets/imgs/zzjg.png" alt="" />
            组织架构
          </p>
        </div>
        <div class="search">
          <el-input placeholder="组织名称" v-model="form.input2" size="mini">
            <template slot="append">
              <i class="el-icon-search" @click="search"></i>
            </template>
          </el-input>
        </div>

        <div class="company scrollbar_width0" ref="company_main">
          <div  v-show="form.state==1">
              <div v-for="(item, index) in treeList" :key="item.name">
              <el-tooltip
                v-show="item.name.length >= 20"
                class="item"
                effect="light"
                :content="item.name"
                placement="top-start"
              >
                <p
                  class="project_item"
                  @click="projectPChange(item, index)"
                  :class="projectP == index ? 'active' : ''"
                >
                  <i class="el-icon-office-building"></i>
                  <span style="margin-left: 4px">{{ item.name }}</span>
                </p>
              </el-tooltip>
              <p
                class="project_item"
                v-show="item.name.length < 20"
                @click="projectPChange(item, index)"
                :class="projectP == index ? 'active' : ''"
              >
                <i class="el-icon-office-building"></i
                ><span style="margin-left: 4px">{{ item.name }}</span>
              </p>
            </div>
          </div>
          <el-tree
          v-show="form.state==0"
            :data="treeData"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
          
        </div>
      </aside>
      <div class="index_right">
        <!-- <img src="../assets/imgs/btn_left.png" alt="" v-if="hide==1" @click="hide=0" class="hide">
        <img src="../assets/imgs/btn_right.png" alt="" v-if="hide==0" @click="hide=1" class="hide"> -->
        <p class="title" style="font-size: 14px">
          {{ companyName }}
        </p>
        <p class="search">
          <el-input
            type="text"
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入用户名/账号"
          ></el-input>
          <button
          v-if="permissoin.includes('query')"
            class="btn1"
            @click="
              currentPage2 = 1;
              getAllUserName();
            "

          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            @click="modal = 1;modalState=0"
            v-if="permissoin.includes('add')"
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border max-height='700px'>
            <el-table-column type="index" label="序号" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="name" label="组织名称"> </el-table-column>
            <el-table-column label="组织类型">
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                    ? "集团总部"
                    : scope.row.type == 2
                    ? "团队"
                    : "项目"
                }}
              </template>
            </el-table-column>
            <el-table-column label="负责人" prop="headName"> </el-table-column>
            <el-table-column label="联系电话" prop="phone"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit"
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-delete"
                      v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            @size-change="handleSizeChange"
            prev-text="上一页"
            next-text="下一页"
            :page-size="pageSize"
            :page-sizes="[5,10,15]"
            layout="total,sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-if="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState==0">新增组织机构</span>
          <span v-if="modalState==1">编辑组织机构</span>
          <span
            @click="
              ruleForm = {};
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <el-form
            :model="ruleForm"
            :rules="rules" status-icon
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            
            <!-- <el-form-item label="组织类型:" prop="type">
              <el-select v-model="ruleForm.type" size="mini">
                <el-option
                  v-for="item in optionType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="组织类型" prop="type">
              <el-radio-group v-model="ruleForm.type" size="mini">
                <el-radio :label="1">集团总部</el-radio>
                <el-radio :label="2">团队</el-radio>
                <el-radio :label="3">项目</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="组织名称:" prop="name">
              <el-input v-model="ruleForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="所属机构:" prop="id">
              <el-cascader
              :collapse-tags='true'
                ref="cas"
                size="mini"
                v-model="ruleForm.id"
                :props="props"
                @change="nodeClick"
                :options="treedata"
                filterable
              >
              </el-cascader>
            </el-form-item>
            <!-- <el-form-item label="机构编码:" prop="name" placeholder="分公司名称首字母,组织全称首字母">
                <el-input v-model="ruleForm.name" size='mini'></el-input>
              </el-form-item> -->
            <p
              style="line-height: 30px; margin-top: -6px"
              v-show="ruleForm.type == 3"
            >
              项目信息设置
            </p>
            <el-form-item
              label="建筑类型:"
              prop="name"
              v-show="ruleForm.type == 3"
            >
              <!-- <el-input v-model="ruleForm.buildingType" size="mini"></el-input> -->
              <el-select v-model="ruleForm.orgType" size="mini">
                <el-option
                  v-for="item in optionbuildingType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负 责 人:" prop="headName">
              <el-input v-model="ruleForm.headName" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="phone">
              <el-input v-model="ruleForm.phone" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="开 竣 工" v-show="ruleForm.type == 3">
              <el-col :span="7">
                <el-form-item prop="startTime">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    v-model="ruleForm.startTime"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="7">
                <el-form-item prop="endTime">
                  <el-date-picker
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    size="mini"
                    v-model="ruleForm.endTime"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <div class="two_item" v-show="ruleForm.type == 3">
              <div>
                <span class="ti_label">工程造价:</span
                ><el-input v-model="ruleForm.orgPrice" size="mini"></el-input
                ><span class="gray999 little">万元</span>
              </div>
              <div>
                <span class="ti_label1">建筑面积:</span
                ><el-input v-model="ruleForm.orgSize" size="mini"></el-input
                ><span class="gray999 little">万㎡</span>
              </div>
            </div>
          </el-form>
          <div class="mc_right" v-show="ruleForm.type == 3">
            <div class="mcr_title">
              <i class="el-icon-location"></i>项目位置
            </div>
            <div class="input_item">
                <span>*</span><span>项目进度</span>
                <el-radio-group v-model="ruleForm.schedule" size="mini">
                <el-radio :label="0">工程筹备</el-radio>
                <el-radio :label="1">开发中</el-radio>
                <el-radio :label="2">项目完工</el-radio>
              </el-radio-group>
            </div>
            <div class="input_item">
              <span>*</span><span>项目地址</span>
              <el-input v-model="ruleForm.orgAddress" size="mini"></el-input>
            </div>
            <p class="jwd">
              <span class="gray999">请在地图上获取项目位置</span>
              <span>{{ ruleForm.coordinates }}</span>
            </p>
            <div class="map">
              <PtojectMap @childByValue="childByValue"></PtojectMap>
            </div>
          </div>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              ruleForm = {};
              modal = 0;
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAll,sysOrgGetOrgList,sysOrgGetOrGet,
  sysOrgSave,sysOrgGetOrDel,sysOrgEdit
} from "@/api/apis.js";
import { timestampToTime1 } from "@/utils/getTime.js";
import PtojectMap from "@/components/PtojectMap.vue";
export default {
  data() {
    var checkIdmobile = (rule, value, cb) => {    
      if ( /^1[3-9]\d{9}$/.test(value)) {
        return cb();
      }
      cb(new Error("您输入的电话号码不是有效格式"));
    };
    return {
      option1: [],
      
      form: {state:0,modalState:0},
      timestampToTime1,
      info: 1,
      currentPage2: 1,
      treeData: [],
      treeList:[],
      hide: 1,
      modal: 0,
      companyName: "",
      total: 0,
      permissoin: [],
      searchForm: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      ruleForm: {
        pwd: 123456,
        date1: "",
        date2: "",
        schedule:1,
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      treedata: [],
      projectP:-1,
      pageSize:10,
      optionType: [
        {
          value: 1,
          label: "集团总部",
        },
        {
          value: 2,
          label: "团队",
        },
        {
          value: 3,
          label: "项目",
        },
      ],
      optionbuildingType: [
        {
          value: 1,
          label: "房屋",
        },
        {
          value: 2,
          label: "市政",
        },
      ],
      dialogVisible:false,
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择组织类型", trigger: "change" }],
        orgType: [
          { required: true, message: "请选择建筑类型", trigger: "change" },
        ],
        headName: [
          { required: true, message: "请输入负责人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入负责人联系电话", trigger: "blur" },
          { validator: checkIdmobile, trigger: "blur" }, 
        ],
        id: [{ required: true, message: "请选择机构", trigger: "change" }],
        // startTime: [{ required: true, message: "请选择开工时间", trigger: "blur" }],
        // endTime: [{ required: true, message: "请选择竣工时间", trigger: "blur" }],
      },
    };
  },
  components: {
    PtojectMap,
  },
  methods: {
    getdata(){
      getAll().then((res) => {
      if (res.code == 200) {
        this.treeData = res.data;
        this.treedata = res.data;
      } else {
        this.treeData = [];
      }
    });
    },
    projectPChange(v,i){
      this.projectP=i
        this.currentId = v.id;
        this.companyName = v.name;
      this.getAllUserName();
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getAllUserName();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAllUserName();
    },
    handleNodeClick(data) {
      this.companyName = data.name;
      this.currentId = data.id;
      this.currentPage2=1
      this.getAllUserName();
    },
    nodeClick() {
      let a = this.$refs.cas.getCheckedNodes()[0].data;
      this.ruleForm.pidname = a.name;
      this.ruleForm.pid = a.id;
      this.ruleForm.pids = a.pids + "," + a.id;
      this.$forceUpdate();
    },
    getAllUserName() {
      let data = {
        id: this.currentId,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: this.pageSize,
      };
      sysOrgGetOrgList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total-0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    deletePro(){
      let data ={id:this.form.id}
        sysOrgGetOrDel(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
            this.dialogVisible=false
            this.getAllUserName()
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
    onsubmit() {
      
      if(this.modalState==0){
        let data = {};
      if (this.ruleForm.type == 3) {
        data = {
          name: this.ruleForm.name,
          type: this.ruleForm.type,
          pid: this.ruleForm.pid,
          pids: this.ruleForm.pids,
          orgType: this.ruleForm.orgType,
          headName: this.ruleForm.headName,
          phone: this.ruleForm.phone,
          startTime: this.ruleForm.startTime,
          endTime: this.ruleForm.endTime,
          orgPrice: this.ruleForm.orgPrice,
          orgSize: this.ruleForm.orgSize,
          orgAddress: this.ruleForm.orgAddress,
          coordinates: this.ruleForm.coordinates,
          areaName: this.ruleForm.areaName,
          schedule: this.ruleForm.schedule,
          solt:1,
        };
      } else {
        data = {
          name: this.ruleForm.name,
          type: this.ruleForm.type,
          pid: this.ruleForm.pid,
          pids: this.ruleForm.pids,
          headName: this.ruleForm.headName,
          phone: this.ruleForm.phone,
          solt:1,
        };
      }
       this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            sysOrgSave(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          getAll().then((res) => {
            if (res.code == 200) {
              this.treeData = res.data;
            }
          });
          this.modal = 0;
          this.ruleForm = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
          } else {
            return false;
          }
        });
         
      }else if(this.modalState==1){
        let data = {};
      if (this.ruleForm.type == 3) {
        data = {
          name: this.ruleForm.name,
          type: this.ruleForm.type,
          pid: this.ruleForm.pid,
          pids: this.ruleForm.pids,
          orgType: this.ruleForm.orgType,
          headName: this.ruleForm.headName,
          phone: this.ruleForm.phone,
          startTime: this.ruleForm.startTime,
          endTime: this.ruleForm.endTime,
          orgPrice: this.ruleForm.orgPrice,
          orgSize: this.ruleForm.orgSize,
          orgAddress: this.ruleForm.orgAddress,
          coordinates: this.ruleForm.coordinates,
          areaName: this.ruleForm.areaName,
          id:this.form.id,
          schedule: this.ruleForm.schedule,
          solt:1
        };
      } else {
        data = {
          name: this.ruleForm.name,
          type: this.ruleForm.type,
          pid: this.ruleForm.pid,
          pids: this.ruleForm.pids,
          headName: this.ruleForm.headName,
          phone: this.ruleForm.phone,
          id:this.form.id,
          solt:1
        };
      }
         sysOrgEdit(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getAllUserName()
          this.modal = 0;
          this.ruleForm = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
      }
     
    },
    search(){
      if(this.form.input2!=''){
        this.form.state=1
        let data={name:this.form.input2}
      getAll(data).then(res=>{
        if(res.code==200){
          this.treeList=res.data
        }
      })
      }else{
        this.form.state=0
        this.getdata()
      }
      
    },
    handleCommand(d) {
      this.form.id=d.data.id
      if(d.num==3){
        this.dialogVisible=true      
      }else if(d.num==2){
        let  data={id:d.data.id}    
        sysOrgGetOrGet(data).then(res=>{
            if(res.code==200){
              this.modalState=1
          this.modal=1
          this.ruleForm=res.data
          this.ruleForm.startTime=this.timestampToTime1(this.ruleForm.startTime)
          this.ruleForm.endTime=this.timestampToTime1(this.ruleForm.endTime)
        }else{
          this.$message.error(res.data.msg);
        }
        })
      }
    },
    //接收地图传输数据
    childByValue(v) {
      console.log(v);
      this.ruleForm.latitude = v.lat;
      this.ruleForm.longitude = v.lng;
      this.ruleForm.orgAddress = v.address;
      this.ruleForm.areaName = v.areaName;
      this.ruleForm.coordinates=this.ruleForm.longitude + "," + this.ruleForm.latitude
      this.$forceUpdate();
    },
  },
  mounted() {
    
    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    getAll().then((res) => {
      if (res.code == 200) {
        this.treeData = res.data;
        this.treedata = res.data;
        this.currentId = res.data[0].id;
        this.companyName = res.data[0].name;
        this.getAllUserName();
      } else {
        this.treeData = [];
      }
    });
    this.$refs.company_main.style.height =
      this.$refs.index_main.offsetHeight*0.806 + "px";
  },
};
</script>

<style lang="less" >
.O_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 780px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
          border-bottom: 1px dashed #ebf0f5;
          font-size: 12px;
          padding-left: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .active {
          color: #466cd4;
        }
    }
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 268px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      // width: 456px;
      // height: 316px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        padding: 0 30px;
        padding-top: 10px;
        display: flex;
        box-sizing: border-box;
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            width: 60px;
          }
        }
        .el-form {
          .el-form-item__label {
            font-size: 12px;
            color: #222328;
          }
          .el-form-item {
            margin-bottom: 10px;
          }
          .el-input {
            width: 270px;
          }
          .el-form-item__error {
            padding-top: 0px;
          }
          // .el-form-item__content{
          //   // margin-left: 0px !important;
          //   // width: 302px;
          // }
          .two_item {
            display: flex;
            .ti_label {
              display: inline-block;
              width: 80px;
            }
            .ti_label1 {
              display: inline-block;
              width: 55px;
              margin-left: 14px;
              margin-right: 2px;
            }
            .el-input {
              width: 80px;
            }
            .little {
              font-size: 8px;
            }
          }
        }
        .mc_right {
          width: 400px;
          .mcr_title {
            margin-top: 18px;
            margin-bottom: 22px;
            i {
              font-size: 14px;
              color: #f37d00;
            }
          }
          .input_item {
            margin-bottom: 24px;

            & > span:nth-child(1) {
              color: #f56c6c;
              margin-right: 2px;
            }
            & > span:nth-child(2) {
              width: 60px;
              display: inline-block;
              margin-right: 8px;
            }
            .el-input {
              width: 270px;
            }
          }
          .jwd {
            display: flex;
            justify-content: space-between;
            font-size: 8px;
            line-height: 18px;
            margin-top: -24px;
            width: 350px;
            box-sizing: border-box;
            padding: 0 6px;
            & > span:nth-child(2) {
              color: #0090ff;
            }
          }
          .map {
            // background-color: rgb(116, 35, 35);
            width: 360px;
            height: 310px;
          }
        }
      }
      .bottom {
        height: 54px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>